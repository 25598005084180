import { useCommonToolbarItems } from '@app/web/src/hooks/utils/useCommonToolbarItems';
import { useToolbarMoreButtonDot } from '@app/web/src/hooks/utils/useToolbarMoreButtonDot';
import { ResponsiveToolbar } from '@lib/web/ui';

export default function ClubToolbar() {
  const commonToolbarItems = useCommonToolbarItems();
  const { moreButtonDot } = useToolbarMoreButtonDot();

  return (
    <ResponsiveToolbar
      items={commonToolbarItems}
      moreButtonDot={moreButtonDot}
    />
  );
}
