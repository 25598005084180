import { useCallback, useState } from 'react';

import useRouterEventsTrigger from './useRouterEventsTrigger';

export default function useRouteChangeListener(creationPath: string) {
  const [state, setState] = useState<'default' | 'loading' | 'error'>();

  useRouterEventsTrigger({
    events: {
      changeStart: () => setState('loading'),
      changeComplete: () => setState('default'),
      changeError: () => setState('error'),
    },
    when: (url) => url === creationPath,
  });

  const resetState = useCallback(() => {
    setState('default');
  }, []);

  return {
    state,
    resetState,
  };
}
