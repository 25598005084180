import { useEffect } from 'react';
import Router from 'next/router';
import { useLatestValueRef } from '@front/helper';
import { isDev } from '@lib/web/apis';
import { clearRouteParams } from '@lib/web/utils';

type Options = {
  events: {
    changeStart?: (url: string) => void;
    changeComplete?: (url: string) => void;
    changeError?: (url: string) => void;
  };
  when?: (url: string) => boolean;
};

export default function useRouterEventsTrigger({
  events: {
    changeStart = () => {},
    changeComplete = () => {},
    changeError = () => {},
  },
  when = () => true,
}: Options) {
  const funcRef = useLatestValueRef({
    when,
    changeStart,
    changeComplete,
    changeError,
  });

  const mockRouteChangeErrorIfNeededOnNonProdOnly = (url: string) => {
    if (!Router.query.mockRouteChangeError || !isDev) {
      return;
    }

    setTimeout(() => {
      funcRef.current.changeError(url);
      clearRouteParams(['mockRouteChangeError']);
    }, 1000);
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw 'Please ignore this error';
  };

  useEffect(() => {
    const routeChangeStart = (url: string) => {
      if (funcRef.current.when(url)) {
        funcRef.current.changeStart(url);
        // for testing purpose
        mockRouteChangeErrorIfNeededOnNonProdOnly(url);
      }
    };
    const routeChangeComplete = (url: string) => {
      if (funcRef.current.when(url)) {
        funcRef.current.changeComplete(url);
      }
    };
    const routeChangeError = (url: string) => {
      if (funcRef.current.when(url)) {
        funcRef.current.changeError(url);
      }
    };

    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeComplete);
    Router.events.on('routeChangeError', routeChangeError);
    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeComplete);
      Router.events.off('routeChangeError', routeChangeError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
