import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyType } from '@front/ui';
import { CustomDirectionOptionId } from '@lib/ia/src/sort/types';
import { useLocalesInfo, useMyProfile } from '@lib/web/apis';

import { useIaSortActions } from './useIaSortActions';

type Options = {
  onInitComplete: () => void;
};

export default function useClubDefaultSort({ onInitComplete }: Options) {
  const { setCriteria } = useIaSortActions();
  const { data: profileRes } = useMyProfile();
  const profile = profileRes?.data;
  const { data: localeRes } = useLocalesInfo();
  const { t } = useTranslation();
  useEffect(() => {
    if (!localeRes || !profile) return;
    const locales = localeRes.data;
    setCriteria([
      {
        id: 'bestMatches',
        field: {
          label: t('sort.priority.label', 'Priority'),
          name: 'priority',
          type: PropertyType.Select,
          customDirectionOptions: [
            {
              value: 'customDir_bestMatches',
              label: t('sort.priority.options.bestMatches', 'Best Matches'),
              direction: 'desc',
            },
          ],
        },
        direction: 'customDir_bestMatches',
        disabled: true,
        viewOnly: true,
      },
      {
        id: 'locale',
        field: {
          label: t('sort.userLocale.label', 'User Locale'),
          name: 'priorityLocale',
          type: PropertyType.Select,
          customIcon: 'OtherMultiselect',
          customDirectionOptions: locales.map((locale) => ({
            type: 'rawQuery' as const,
            value: `customDir_${locale.id}` as CustomDirectionOptionId,
            label: locale.name,
            rawQueryParams: {
              search: `priorityLocale:${locale.id}`,
              searchFields: 'priorityLocale:in',
            },
          })),
        },
        direction:
          `customDir_${profile.userLocale.id}` as CustomDirectionOptionId,
      },
      {
        id: 'officialType',
        field: {
          label: t('sort.clubType.label', 'Club Type'),
          name: 'officialType',
          type: PropertyType.Status,
          customDirectionOptions: [
            {
              value: 'customDir_official',
              label: t('sort.clubType.options.official', 'official'),
              direction: 'desc',
            },
            {
              value: 'customDir_nonOfficial',
              label: t('sort.clubType.options.nonOfficial', 'Non-Official'),
              direction: 'asc',
            },
          ],
        },
        direction: 'customDir_official',
      },
      {
        id: 'clubMember',
        field: {
          label: t('sort.clubMembers.label', 'Club Members'),
          name: 'clubMemberCount',
          type: PropertyType.Number,
        },
        direction: 'desc',
      },
      {
        id: 'clubName',
        field: {
          label: t('sort.clubName.label', 'Club Name'),
          name: 'clubName',
          type: PropertyType.ShortText,
        },
        direction: 'asc',
      },
    ]);
    onInitComplete?.();
  }, [t, localeRes, profile, setCriteria, onInitComplete]);
}
