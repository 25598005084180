import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import type { NextPage } from 'next';
import Router from 'next/router';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import SEOHeader from '@app/web/src/components/SEOHeader';
import MainLayout from '@app/web/src/layouts/MainLayout';
import PageTitle from '@app/web/src/layouts/MainLayout/components/PageTitle';
import RootLayout from '@app/web/src/layouts/RootLayout';
import { appConfig } from '@front/config';
import {
  BaseLayoutScrollContainer,
  PropertyType,
  SquareAvatar,
  useBaseLayout,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import {
  CardCornerAvatarEvent,
  CardLayoutItem,
} from '@lib/ia/src/layouts/CardLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import combineSort from '@lib/ia/src/utils/combineSort';
import { ClubOfficialType, ClubViewSlug, useIaClubsList } from '@lib/web/apis';
import { useInfiniteScroll } from '@lib/web/hooks';
import { ResponsiveToolbar, SearchToolbarItem } from '@lib/web/ui';
import { isEqual } from 'lodash';

import QuickFilterChips from '../src/components/QuickFilterChips';
import { FilterChipItem } from '../src/components/QuickFilterChips/types';
import useClubDefaultSort from '../src/hooks/utils/useClubDefaultSort';
import useFloatingProfile from '../src/hooks/utils/useFloatingProfile';
import useIaFilterActions from '../src/hooks/utils/useIaFilterActions';
import { useIaSearchActions } from '../src/hooks/utils/useIaSearchActions';
import usePageSearchFilterSort from '../src/hooks/utils/usePageSearchFilterSort';
import useRouteChangeListener from '../src/hooks/utils/useRouteChangeListener';
import { useIaClubCtaAvailableActions } from '../src/ia/club/iaClubCta';
import useIaClubCardItem from '../src/ia/club/useIaClubCardItem';
import { GlobalPanelKeys, GlobalPanelParams } from '../src/types/panel';
import ClubToolbar from '../src/widgets/ClubsPage/ClubToolbar';

const styles = {
  root: {
    pt: { xs: 2, sm: 1 },
    pb: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  result: {
    opacity: 0.75,
  },
  action: {
    display: { md: 'flex' },
    justifyContent: { md: 'flex-end' },
    alignItems: { md: 'flex-end' },
    '& .MuiButtonBase-root': {
      width: { xs: '100%', md: 'auto' },
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  topBarContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  topBarContainerMobile: {
    flexDirection: 'column-reverse',
    alignItems: 'start',
    gap: '20px',
    svg: {
      width: '16px',
      height: '16px',
    },
  },
  chipContainer: {
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
    width: '100%',
  },
  chipIconText: {
    fontSize: 4.8,
    whiteSpace: 'pre-line',
    lineHeight: '5px',
    color: 'text.primary',
  },
};

const loadingCardLayout = {
  layout: 'card-layout-skeleton' as const,
  settings: {
    columns: [1, 2, 3, 4, 6],
    cardHeight: 220,
  },
};

type Props = {
  searchFrom?: string;
};

const HomePage: NextPage<Props> = ({ searchFrom }) => {
  const { t } = useTranslation('club');
  const {
    debouncedKeyword,
    keyword,
    sort,
    filter,
    rawQueryParams,
    isInitializing,
    hasFilter,
    hasSort,
    completeInitPartial,
  } = usePageSearchFilterSort({
    type: 'club',
    waitUntilInitComplete: {
      discoverClubs_filterInitializing: !searchFrom,
      discoverClubs_sortInitializing: !searchFrom,
    },
  });
  const { setConditions, forceResetFilter } = useIaFilterActions();
  const { showAhaProfile, showUserProfile } = useFloatingProfile();
  const [selectedFilter, setSelectedFilter] = useState<FilterChipItem>();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const handleOnDefaultSortInitialized = useCallback(() => {
    completeInitPartial('discoverClubs_sortInitializing');
  }, [completeInitPartial]);

  void useClubDefaultSort({
    onInitComplete: handleOnDefaultSortInitialized,
  });

  const filterDeferred = useDeferredValue(filter);
  const isFilterStale = !isEqual(filter, filterDeferred);

  const clubsData = useIaClubsList(
    {
      viewSlug: ClubViewSlug.CardCenterDefault,
      ...filterDeferred,
      ...combineSort(sort),
      keywordFuzzy: debouncedKeyword,
      rawQueryParams,
      limit: 20,
    },
    {
      enable: !isInitializing,
    }
  );
  const {
    dataset,
    isLoadingInitialData,
    isLoadingMore,
    mutate,
    error,
    totalCount,
  } = clubsData;
  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: clubsData,
    enabled: !isInitializing,
  });

  const isLoading = isFilterStale || isLoadingInitialData;
  const isError = !!error;

  const { mapToCardItem, getEmptyAction } = useIaClubCardItem();
  const { state: creationState, resetState: onCreationErrorDismiss } =
    useRouteChangeListener('/club/form/overview');

  const layouts = useMemo(() => {
    if (isLoading) return [loadingCardLayout];
    const items = dataset.map<CardLayoutItem>(mapToCardItem);

    const shouldShowEmptyAction =
      items.length === 0 ||
      !selectedFilter ||
      selectedFilter.id === 'recommended';

    if (!isError && shouldShowEmptyAction) {
      const emptyAction = getEmptyAction(creationState);
      items.unshift(emptyAction);
    }

    return [
      {
        layout: 'card-layout' as const,
        settings: {
          columns: [1, 2, 3, 4, 6],
          descriptionLines: 2,
          avatarSize: 40,
          emptyText: !isError
            ? t('No clubs')
            : t(
                'cardLayout.error.message',
                'Unable to load clubs. Please refresh the page or try again later.'
              ),
          emptyIcon: !isError ? undefined : 'NFTEmojiCryingFace',
          optimizeRenderEnabled: true,
        },
        groups: [
          {
            id: 'items',
            title: !isError ? t('## clubs', { count: totalCount }) : '',
            items,
          },
        ],
      },
      ...(isLoadingMore ? [loadingCardLayout] : []),
    ];
  }, [
    isLoading,
    dataset,
    mapToCardItem,
    isError,
    t,
    totalCount,
    isLoadingMore,
    getEmptyAction,
    creationState,
    selectedFilter,
  ]);

  const availableActions = useIaClubCtaAvailableActions({
    onJoined: (target: CardLayoutItem) => {
      mutate();
      Router.push(`/club/${target.id}/start`);
    },
    onAccepted: (target: CardLayoutItem) => {
      mutate();
      Router.push(`/club/${target.id}/start`);
    },
    onRequested: () => {
      mutate();
    },
    onCanceled: () => {
      mutate();
    },
    cornerAvatarActions: {
      onMouseEnter: (event: CardCornerAvatarEvent) => {
        if (event.target.metadata?.userProfile) {
          showUserProfile({
            anchorEl: event.anchorEl,
            userProfile: event.target.metadata.userProfile,
          });
          return;
        }
        showAhaProfile({
          anchorEl: event.anchorEl,
          extension: event.target.metadata?.clubInfo && {
            type: 'club',
            data: event.target.metadata.clubInfo,
          },
        });
      },
    },
    onCreationErrorDismiss,
  });

  const filterChips: (FilterChipItem | false)[] = [
    {
      id: 'recommended',
      label: t('filterChips.recommended.label', 'Recommended'),
      tooltip: t('filterChips.recommended.tooltip', 'Show Recommended clubs'),
      icon: 'TestLikeFilled',
      conditions: [
        {
          fieldName: 'isRecommended',
          operator: 'IsTrue',
        },
      ],
      orderFirst: true,
    },
    {
      id: 'sat',
      label: 'SAT',
      tooltip: t('filterChips.sat.tooltip', 'Show SAT clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="#FF7401">
          <Typography sx={styles.chipIconText}>SAT</Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'SAT',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
    {
      id: 'act',
      label: 'ACT',
      tooltip: t('filterChips.act.tooltip', 'Show ACT clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="primary.light">
          <Typography sx={styles.chipIconText}>ACT</Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'ACT',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
    {
      id: 'ap',
      label: 'AP',
      tooltip: t('filterChips.ap.tooltip', 'Show AP clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="#7935CB">
          <Typography sx={styles.chipIconText}>AP</Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'AP',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
    {
      id: 'cbse10',
      label: 'CBSE 10',
      tooltip: t('filterChips.cbse10.tooltip', 'Show CBSE 10th clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="primary.dark">
          <Typography sx={styles.chipIconText} fontSize={4}>
            CBSE{'\n'}10
          </Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'CBSE 10',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
    {
      id: 'cbse12',
      label: 'CBSE 12th',
      tooltip: t('filterChips.cbse12.tooltip', 'Show CBSE 12th clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="#00C398">
          <Typography sx={styles.chipIconText} fontSize={4}>
            CBSE{'\n'}12
          </Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'CBSE 12th',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
  ];

  const handleFilterChange = (item?: FilterChipItem) => {
    setSelectedFilter(item);
  };

  const { setKeyword } = useIaSearchActions();

  const handleOnSearch = useCallback(
    (value?: string): void => {
      setKeyword(value);
    },
    [setKeyword]
  );

  const { toggleRightPanel, isTargetPanelOpened } =
    useBaseLayout<GlobalPanelParams>();

  useEffect(() => {
    if (!searchFrom) {
      setConditions([
        {
          id: 'isRecommended',
          field: {
            label: t('filter.recommended.label', 'Recommended'),
            name: 'isRecommended',
            type: PropertyType.Boolean,
          },
          operator: 'IsTrue',
        },
      ]);
    }
    completeInitPartial('discoverClubs_filterInitializing');
  }, [t, searchFrom, setConditions, completeInitPartial]);

  useEffect(() => {
    return () => {
      forceResetFilter();
    };
  }, [forceResetFilter]);

  return (
    <BaseLayoutScrollContainer sx={styles.root} fullWidth scrollRef={scrollRef}>
      <PageTitle />
      <Box sx={styles.container}>
        <Box
          sx={[styles.topBarContainer, mdDown && styles.topBarContainerMobile]}
        >
          <Box sx={styles.chipContainer}>
            <QuickFilterChips
              filterType="club"
              sortType="club"
              items={filterChips}
              allItem={{
                icon: 'MainApps',
                title: t('filterChips.all.label', 'All'),
                tooltip: t('filterChips.all.tooltip', 'Show all clubs'),
              }}
              state={isError ? 'error' : isLoading ? 'loading' : 'default'}
              onChange={handleFilterChange}
            />
          </Box>
          <Box sx={styles.searchContainer}>
            <SearchToolbarItem
              type="Search"
              onSearch={handleOnSearch}
              keyword={keyword}
              placeholder={t('search.placeholder_club', 'Search club...')}
              expand
              fullWidth
            />
            <ResponsiveToolbar
              items={[
                {
                  type: 'Filter',
                  onClick: () => {
                    toggleRightPanel(GlobalPanelKeys.GlobalFilter);
                  },
                  selected: isTargetPanelOpened(GlobalPanelKeys.GlobalFilter),
                  highlight: hasFilter,
                  customConfig: {
                    visible: true,
                  },
                },
                {
                  type: 'Sort',
                  onClick: () => {
                    toggleRightPanel(GlobalPanelKeys.GlobalSort);
                  },
                  selected: isTargetPanelOpened(GlobalPanelKeys.GlobalSort),
                  highlight: hasSort,
                  customConfig: {
                    visible: true,
                  },
                },
              ]}
            />
          </Box>
        </Box>
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={layouts} />
        </IaActionContextProvider>
      </Box>
    </BaseLayoutScrollContainer>
  );
};

HomePage.getHead = () => <SEOHeader title="Discover Clubs" />;

HomePage.getInitialProps = async (context) => ({
  searchFrom: (context.query?.searchFrom || '') as string,
});
HomePage.getLayout = (page) => (
  <RootLayout>
    <MainLayout toolComponent={<ClubToolbar />}>{page}</MainLayout>
  </RootLayout>
);

export default HomePage;
